import { FormatUtils } from "@front/volcanion/utils"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'deleted', 'suspended'],
        ...filter
      }
    }
  }
  static formToFilterHandler(codeIntlPhone, group) {
    return function formToFilter(values, extra, meta, state) {
      const { average_rating_start, average_rating_end, status, ...rest } = values
      return _.merge({},
        rest,
        { driverinfo: { '!': null } },
        !_.isEmpty(status) && { auth: FormatUtils.getParseFilterStatus(status) },
        !!group && { groups: [group] },
        !!rest?.auth?.gsm && { auth: { gsm: FormatUtils.parsePhoneNumber(rest?.auth?.gsm, `+${codeIntlPhone}`) } },
        !!rest?.info?.last_name && { info: { last_name: { startsWith: rest?.info?.last_name } } },
        !!rest?.info?.first_name && { info: { first_name: { startsWith: rest?.info?.first_name } } },
        !!rest?.auth?.email && { auth: { email: { contains: rest?.auth?.email } } },
        !!average_rating_start && { score: { average_rating: { '>=': average_rating_start } } },
        !!average_rating_end && { score: { average_rating: { '<=': average_rating_end } } },
        !!rest?.vehicle?.info?.plaque && { vehicle: { info: { plaque: { contains: rest?.vehicle?.info?.plaque } } } }
      )
    }
  }
}

export default Callbacks
