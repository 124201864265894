import React, { useState, useCallback } from 'react'
import { hooks, I18n, CDNImage } from '@front/volcanion'

import { Box, Fade, IconButton, Button, Typography } from '@mui/material'
import { TextComponent } from '@front/squirtle'

import { Visibility, VisibilityOff } from '@mui/icons-material/'
import QRCode from "react-qr-code";

const AuthenticationPage = (props) => {
  const { openNotification } = hooks.useNotification()
  const [username, onUsernameChange] = useState()
  const [password, onPasswordChange] = useState()
  const [display_2fa_code, setDisplay2FACode] = useState(false)
  const [_2fa_info, set2FAInfo] = useState()
  const [_2fa_code, set2FACode] = useState()

  const [displayPassword, setDisplayPassword] = useState(false)
  const [, signin] = hooks.useAuthFunction('signin')

  const handleSubmit = useCallback(async () => {
    try {
      await signin({ username, password, _2fa_secret: _2fa_info?.secret, _2fa_code })
    }
    catch (err) {
      if (_.includes(['PASSWORD_INCORRECT', 'RECORD_NOTFOUND'], err.getCode()))
        openNotification(I18n.t('login.error'), { variant: 'error' })
      else if (_.includes(['2FA_REQUIRED'], err.getCode())) {
        set2FAInfo(err.context)
        setDisplay2FACode(true)
      }
      else if (_.includes(['2FA_MISSING'], err.getCode()))
        setDisplay2FACode(true)
      else
        openNotification(I18n.t('error.unknow'), { variant: 'error' })
    }
  }, [openNotification, username, password, signin, _2fa_info, _2fa_code])

  hooks.useGlobalKeyListener(handleSubmit, ['Enter'], { disabled: !username || !password })

  return (
    <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      <Fade in timeout={3000}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: 6, borderRadius: 1, p: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }} gap={5}>
            <CDNImage name='logo' style={{ maxHeight: 45 }} />
            <TextComponent
              label={I18n.t('login.label')}
              name='username'
              type='text'
              autoFocus
              value={username}
              onChange={onUsernameChange}
            />
            <TextComponent
              label={I18n.t('password.label', { count: 1 })}
              name='password'
              type={!!displayPassword ? 'text' : 'password'}
              value={password}
              onChange={onPasswordChange}
              InputProps={{
                endAdornment: <IconButton onClick={() => setDisplayPassword(!displayPassword)}>
                  {!!displayPassword ? <VisibilityOff color='error' /> : <Visibility color='primary' />}
                </IconButton>
              }}
            />
            {!!_2fa_info &&
              <Box gap={5} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                <QRCode value={_2fa_info?.uri} />
                <Typography variant='body2'>{_2fa_info?.secret}</Typography>
              </Box>
            }
            {!!display_2fa_code &&
              <TextComponent
                label={I18n.t('2fa_code.label')}
                name='code'
                type='text'
                value={_2fa_code}
                onChange={set2FACode}
              />
            }
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Button onClick={handleSubmit} variant={'outlined'}>
                {I18n.t('action.validate')}
              </Button>
            </Box>
          </Box>
        </Box >
      </Fade >
    </Box >
  )
}

export default React.memo(AuthenticationPage)
