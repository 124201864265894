import { FormatUtils } from "@front/volcanion/utils"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active'],
        ...filter
      }
    }
  }
  static filterToStateHandler(company) {
    return function filterToState(state) {
      return { company }
    }
  }
  static formToFilterHandler(codeIntlPhone) {
    return function formToFilter(values, extra, meta, state) {
      const { status, ...rest } = values
      return _.merge({},
        rest,
        { company: state?.company },
        !_.isEmpty(status) && FormatUtils.getParseFilterStatus(status),
        rest?.contact?.last_name && { contact: { last_name: { contains: rest?.contact?.last_name } } },
        rest?.contact?.first_name && { contact: { first_name: { contains: rest?.contact?.first_name } } },
        rest?.contact?.gsm && { contact: { gsm: { contains: FormatUtils.parsePhoneNumber(rest?.contact?.gsm, `+${codeIntlPhone}`) } } },
        rest?.contact?.email && { contact: { email: { contains: rest?.contact?.email } } },
      )
    }
  }
}

export default Callbacks
