import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { ModelAutocompleteField, TextField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={9}>
    <Typography variant={'h5'}> {I18n.t('company.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <ModelAutocompleteField
        name={'company_id'}
        label={I18n.t('company.name_client_id')}
        model_name={'company'}
        searchMode='search'
        searchQuery={'startsWith'}
        autocompleteMutator={q => ({
          or: [{ client_id: { startsWith: q } }, { name: { startsWith: q } }]
        })}
        labelKeys={['label']}
        config={{
          mutator: company => ({ label: `${_.get(company, 'client_id')} - ${_.get(company, 'name')}`, ...company })
        }}
        loadOnFocus={false}
        selectSingleOption
        debounceTime={400}
      />
    </Grid>
    <Grid item xs>
      <TextField name='info.nace' label={I18n.t('company.nace')} />
    </Grid>
    <Grid item xs>
      <TextField name='contact.gsm' label={I18n.t('phone.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <TextField name='contact.email' label={I18n.t('email.label', { count: 1 })} />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid item xs={6}>
    <TextField name='contact.address' label={I18n.t('address.label.one')} />
  </Grid >

const SearchForm = props =>
  <Box sx={{ p: 1, boxShadow: 6, borderRadius: 1 }}>
    <Grid container rowSpacing={3}>
      <Title />
      <Row1 />
      <Row2 />
    </Grid>
  </Box>

export default React.memo(SearchForm)
